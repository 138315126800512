// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

*,
*::after,
*::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  overflow-x: hidden;
  background-color: color(lighter-blue);
  line-height: 1.4;
  // font-family: $main-font;
  font-size: $default-font-size;
}

iframe {
  //pointer-events: none;
}

* {
  line-height: inherit;
  font-size: inherit;
  font-weight: inherit;
}
